import ReactPlayer from 'react-player'
import { Pause } from '@styled-icons/boxicons-regular/Pause'
import { Play } from '@styled-icons/boxicons-regular/Play'
import { SkipForward10 } from '@styled-icons/fluentui-system-filled/SkipForward10'
import { SkipBack10 } from '@styled-icons/fluentui-system-filled/SkipBack10'
import { useState, useEffect, useRef } from 'react'
import * as styles from '../styles'
import styled from 'styled-components'
import NextImage from 'next/image'
import { Copy } from 'components/Typography'
import LoadingSpinner from 'components/LoadingSpinner'

const AudioPlayerContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  color: ${styles.OFF_WHITE};
  overflow: hidden;
  position: relative;
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  /* flex: 1 1 auto; */
  min-height: 300px;
  justify-content: center;

  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    flex-basis: auto;
    /* min-height: 150px; */
  }
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1em;
`

const AudioControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  border-top: 1px solid ${({ theme }) => theme.DARKER};
  background-color: ${({ theme }) => theme.DARK_ALPHA};
`

const AudioSlider = styled.input`
  width: 100%;
  height: 1px;
  -webkit-appearance: none;
  background-color: ${({ theme }) => theme.OFF_WHITE};
  cursor: pointer;
  z-index: 5;
  margin-bottom: ${styles.PADDING_MD};
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: ${({ theme }) => theme.OFF_WHITE};
  }
`

const AudioButton = styled.button<{ size?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  width: ${({ size }) => `${size}px` || '50px'};
  height: ${({ size }) => `${size}px` || '50px'};
`

const PlayIcon = styled(Play)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: ${({ color, theme }) => color || theme.OFF_WHITE};
`
const PauseIcon = styled(Pause)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: ${({ color, theme }) => color || theme.OFF_WHITE};
`

const SkipNextIcon = styled(SkipForward10)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: ${({ color, theme }) => color || theme.OFF_WHITE};
`
const SkipBackIcon = styled(SkipBack10)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: ${({ color, theme }) => color || theme.OFF_WHITE};
`

function display(seconds) {
  const format = (val) => `0${Math.floor(val)}`.slice(-2)
  const hours = seconds / 3600
  const minutes = (seconds % 3600) / 60

  return [minutes, seconds % 60].map(format).join(':')
}

const AudioControls = ({
  isLoading,
  isPlaying,
  onPlayPauseClick,
  onPrevClick,
  onNextClick,
}: {
  isPlaying: boolean
  isLoading: boolean
  onPlayPauseClick: (value: boolean) => void
  onPrevClick?: () => void
  onNextClick?: () => void
}) => {
  return (
    <AudioControlsContainer>
      {isPlaying && (
        <AudioButton
          onClick={(e) => {
            e.stopPropagation()
            onPrevClick()
          }}
          size={30}
        >
          <SkipBackIcon />
        </AudioButton>
      )}

      {!isLoading && (
        <AudioButton
          onClick={(e) => {
            e.stopPropagation()
            onPlayPauseClick(!isPlaying)
          }}
          size={50}
        >
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </AudioButton>
      )}

      {isPlaying && (
        <AudioButton
          onClick={(e) => {
            e.stopPropagation()
            onNextClick()
          }}
          size={30}
        >
          <SkipNextIcon />
        </AudioButton>
      )}
    </AudioControlsContainer>
  )
}

const AudioPlayer = ({ src, imgSrc }: { src: string; imgSrc?: string }) => {
  const playerRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [duration, setDuration] = useState('')
  const [imageLoadFailed, setImageLoadFailed] = useState(false)

  const [progress, setProgress] = useState<{
    played: number
    playedSeconds: number
    loaded: number
    loadedSeconds: number
  }>({ played: 0, playedSeconds: 0, loaded: 0, loadedSeconds: 0 })

  const handlePlayPause = (isPlaying: boolean) => {
    setIsPlaying(isPlaying)
  }

  const handleGetProgress = (progress: {
    played: number
    playedSeconds: number
    loaded: number
    loadedSeconds: number
  }) => {
    setProgress(progress)
  }

  const onChange = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    playerRef.current.player.seekTo(parseFloat(event.target.value))
  }

  useEffect(() => {
    if (progress.played >= 0.99) setIsPlaying(false)
  }, [progress])

  return (
    <AudioPlayerContainer>
      {isLoading && (
        <LoadingWrapper>
          <LoadingSpinner />
        </LoadingWrapper>
      )}
      <Wrapper>
        <NextImage
          src={imgSrc}
          layout="fill"
          objectFit="contain"
          placeholder="blur"
          blurDataURL="/header/preload.png"
          onError={() => {
            setImageLoadFailed(true)
          }}
          unoptimized={imageLoadFailed}
        />
      </Wrapper>

      <AudioControls
        isLoading={isLoading}
        isPlaying={isPlaying}
        onPlayPauseClick={handlePlayPause}
        onNextClick={() => {
          playerRef.current.player.seekTo(progress.played + 0.1)
        }}
        onPrevClick={() =>
          playerRef.current.player.seekTo(progress.played - 0.1)
        }
      />

      {!isLoading && isPlaying && (
        <>
          <AudioSlider
            type="range"
            min="0"
            max="1"
            step="any"
            value={progress.played}
            onChange={onChange}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Copy size={'0.9em'}>{display(progress.playedSeconds)}</Copy>
            <Copy size={'0.9em'}>{duration}</Copy>
          </div>
        </>
      )}

      <ReactPlayer
        ref={playerRef}
        url={src}
        controls={false}
        playing={isPlaying}
        height={'0px'}
        width={'0px'}
        onProgress={handleGetProgress}
        onDuration={(duration: number) => {
          setDuration(display(duration))
        }}
      />
    </AudioPlayerContainer>
  )
}

export default AudioPlayer
