import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface IModelViewerProps {
  src: string
  poster?: string
  style?: any
  isBasic?: boolean
}

const Poster = styled.div<{ src?: string }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: ${({ src }) =>
    src
      ? `url(${src})
  `
      : ''};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

const LazyLoadButton = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 100;
`

const ShowModelButton = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Lato', sans-serif;
  align-items: center;
  position: absolute;
  color: white;
  z-index: 3;
  background-color: ${({ theme }) => theme.DARK};
  cursor: pointer;
  border-radius: 100px;
  bottom: 0;
  left: 0;
  margin: 16px;
  padding: 16px;
`

export default function ModelViewer({
  style,
  src,
  isBasic = false,
  poster = '',
  ...restProps
}: IModelViewerProps) {
  const [animations, setAnimations] = useState([])
  useEffect(() => {
    import('@google/model-viewer/dist/model-viewer')
  }, [])

  const elementRef = useRef<any>()

  useEffect(() => {
    elementRef.current.addEventListener('load', handleEvent)
  }, [elementRef])

  const handleEvent = () => {
    const _animations = elementRef.current.availableAnimations ?? ['']
    setAnimations([..._animations])
  }

  return (
    <model-viewer
      style={style || { height: '100%', width: '100%' }}
      ref={elementRef}
      src={src}
      reveal="interaction"
      animation-name={animations?.length > 0 ? animations[0] : ''}
      shadow-intensity="1"
      autoplay={!isBasic}
      ar={true}
      ar-modes="webxr scene-viewer quick-look"
      camera-controls
      {...restProps}
    >
      <Poster src={poster} slot="poster" />
      <LazyLoadButton
        slot="poster"
        onClick={(e) => {
          e.stopPropagation()
        }}
      ></LazyLoadButton>
      <ShowModelButton slot="poster">Click to show 3D model</ShowModelButton>
    </model-viewer>
  )
}
