import { Badge3d } from '@styled-icons/bootstrap/Badge3d'
import { ExitFullscreen } from '@styled-icons/boxicons-regular/ExitFullscreen'
import { Fullscreen } from '@styled-icons/boxicons-regular/Fullscreen'
import { VideoClip as Videos } from '@styled-icons/fluentui-system-filled/VideoClip'
import { Music } from '@styled-icons/foundation/Music'
import AudioPlayer from 'components/AudioPlayer'
import Modal from 'components/Modal'
import ModelViewer from 'components/ModelViewer'
import * as paths from 'content/paths'
import { startsWith } from 'lodash'
import NextImage from 'next/image'
import React, { useState } from 'react'
import styled from 'styled-components'
import * as styles from '../styles'
import { MintMetadata } from './AppContext'

const ImageContainer = styled.div<{ maxHeight: number }>`
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  /* align-items: center; */
  /* max-height: ${({ maxHeight }) => `${maxHeight}px`}; */
  background-color: ${({ theme }) => theme.DARKER};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    flex-basis: auto;
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  min-height: 300px;
  justify-content: center;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    flex-basis: auto;
    /* min-height: 150px; */
  }
`

const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  max-height: 500px;

  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    flex-basis: auto;
    height: auto;
  }
`

const Video = styled.video``
const Source = styled.source``

const FullscreenModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow-y: hidden;
`

const FullscreenButtonContainer = styled.div`
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: 20px;
  z-index: 3;
  background-color: ${({ theme }) => theme.DARK};
  border-radius: 100px;
  cursor: pointer;
  height: 40px;
  width: 40px;
`
const TypePillContainer = styled.div`
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 3;
  align-items: center;
  position: absolute;
  margin: 20px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.DARK};
  height: 40px;
  width: 40px;
`
const AssetContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  overflow: hidden;
`

const FullsizeImage = styled.img`
  height: 100vh;
  object-fit: contain;
`

const FullscreenIcon = styled(Fullscreen)`
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    width: 22px;
  }
`
const ExitFullscreenIcon = styled(ExitFullscreen)`
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    width: 22px;
  }
`

const AssetLoader = ({
  mint,
  metaId,
  autoplay = false,
  smaller = false,
  controls = false,
  enableFullscreen = false,
  isBasic = true,
  maxHeight = 500,
}: {
  mint?: MintMetadata
  metaId?: string
  autoplay?: boolean
  smaller?: boolean
  controls?: boolean
  enableFullscreen?: boolean
  isBasic?: boolean
  maxHeight?: number
}) => {
  const [showFullscreen, setShowFullscreen] = useState<boolean>(false)

  const isAudio = startsWith(mint?.animation_type, 'audio')
  const isVideo = startsWith(mint?.animation_type, 'video')
  const isModel = startsWith(mint?.animation_type, 'model')

  const [imageLoadFailed, setImageLoadFailed] = useState(false)

  const multimediaUrl = `${paths.COLD_ARWEAVE_GATEWAY}${mint?.animation_hash}`
  const coverImageUrl = `${paths.COLD_ARWEAVE_GATEWAY}${mint?.media_hash}`

  return (
    <ImageContainer maxHeight={maxHeight}>
      {!isVideo && !isAudio && enableFullscreen && (
        <FullscreenButtonContainer
          onClick={(event) => {
            event.stopPropagation()
            setShowFullscreen(true)
          }}
        >
          <FullscreenIcon width={'20px'} color={'white'} />
        </FullscreenButtonContainer>
      )}

      {isBasic && (isModel || isVideo || isAudio) && (
        <TypePillContainer>
          {isModel && <Badge3d width={'30px'} color={'white'} />}
          {isAudio && <Music width={'30px'} color={'white'} />}
          {isVideo && <Videos width={'30px'} color={'white'} />}
        </TypePillContainer>
      )}

      {!isBasic && isModel && mint.media && (
        <Wrapper>
          <ModelViewer
            src={mint.animation_url}
            poster={coverImageUrl}
            isBasic={false}
          />
        </Wrapper>
      )}
      {!isBasic && isAudio && mint.media && mint.animation_hash && (
        <AudioPlayer src={multimediaUrl} imgSrc={coverImageUrl} />
      )}
      {!isBasic && !isModel && !isVideo && !isAudio && mint?.media && (
        <Wrapper>
          <NextImage
            src={imageLoadFailed ? mint?.media : coverImageUrl}
            alt={mint?.title}
            layout="fill"
            objectFit="contain"
            placeholder="blur"
            blurDataURL="/header/preload.png"
            onError={() => {
              setImageLoadFailed(true)
            }}
            unoptimized={imageLoadFailed}
          />
        </Wrapper>
      )}

      {isBasic && mint?.media && (
        <Wrapper>
          <NextImage
            src={imageLoadFailed ? mint?.media : coverImageUrl}
            alt={mint?.title}
            layout="fill"
            objectFit="contain"
            placeholder="blur"
            blurDataURL="/header/preload.png"
            onError={() => {
              setImageLoadFailed(true)
            }}
            unoptimized={imageLoadFailed}
          />
        </Wrapper>
      )}

      {!isBasic && isVideo && mint?.media && (
        <VideoWrapper>
          <Video
            width="100%"
            height="100%"
            playsInline
            loop
            controls={controls}
            poster={coverImageUrl}
            autoPlay={autoplay}
          >
            <Source src={`${multimediaUrl}#t=0.1`} type="video/mp4" />
            <Source src={`${multimediaUrl}#t=0.1`} type="video/ogg" />
            <Source src={`${multimediaUrl}#t=0.1`} type="video/webm" />
            <img src={coverImageUrl} />
          </Video>
        </VideoWrapper>
      )}

      {enableFullscreen && !isVideo && !isAudio && showFullscreen && (
        <Modal>
          <FullscreenModal>
            <FullscreenButtonContainer
              onClick={(event) => {
                event.stopPropagation()
                setShowFullscreen(false)
              }}
            >
              <ExitFullscreenIcon width={'20px'} color={'white'} />
            </FullscreenButtonContainer>
            <AssetContainer>
              <FullsizeImage src={coverImageUrl} />
            </AssetContainer>
          </FullscreenModal>
        </Modal>
      )}
    </ImageContainer>
  )
}

export default AssetLoader
