import styled from 'styled-components'
import { keyframes } from 'styled-components'

const Spin = keyframes`
    to {
        -webkit-transform: rotate(360deg)
    }
`

const Spinner = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 5px solid ${({ theme }) => theme.DARK3};
  border-radius: 50%;
  border-top-color: ${({ theme }) => theme.OFF_WHITE};
  animation: ${Spin} 1s linear infinite;
  -webkit-animation: ${Spin} 1s linear infinite;
`

const LoadingSpinner = () => {
  return <Spinner />
}

export default LoadingSpinner
